/**
 * Shards — Demo Page Adjustments
 */

// Dependencies
@import "../../scss/functions";
@import "../../scss/mixins";
@import '../../scss/variables';

// Page loader
.loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  z-index: 1500;
  transition: opacity 500ms ease-in-out;
}

.page-loader {
  width: 40px;
  height: 40px;
  margin: auto;
  top: 50%;
  left: 50%;
  position: absolute;
  margin-top: -20px;
  margin-left: -20px;
  background-color: #333;
  background: theme-color("primary");
  border-radius: 100%;
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  -webkit-animation: pulse-load 1200ms infinite ease-in-out;
  animation: pulse-load 1200ms infinite ease-in-out;
}

// Page loader animations.
@-webkit-keyframes pulse-load {
  0% {
    -webkit-transform: scale(0)
  }
  100% {
    -webkit-transform: scale(1.0);
    opacity: 0;
  }
}

@keyframes pulse-load {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
    opacity: 0;
  }
}

.fb-share-button > span,
.fb-like > span {
  height: 34px !important;
}

// Welcome sections.
.welcome {
  background: $white;
  height: 100vh;
  text-align: center;
  overflow: hidden;
  position: relative;

  h1 {
    font-size: 6.25rem;
    font-weight: 500;
    letter-spacing: -0.3125rem;
    margin-top: 35vh;
    margin-bottom: 20px;
    color: #1f2429;
    line-height: 1;

    @include media-breakpoint-down(xs) {
      font-size: 5.90rem;
    }
  }

  .inner-wrapper {
    position: relative;
    z-index: 3;

    > p {
      margin-bottom: 20px;
      color: #5f738e;
      transition-delay: 150ms;
    }

    > .action-links {
      transition-delay: 300ms;
    }
  }

  .product-by {
    position: relative;
    z-index: 3;
    margin-bottom: 30px;

    a:hover {
      text-decoration: none;
    }

    p {
      font-size: 10px;
      color: #b1b4bd;
      text-transform: uppercase;
      margin: 0;
    }

    img {
      max-width: 180px;
    }
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 50vw;
    height: 80vh;
    transform: rotate(-25deg);
    z-index: 1;
  }

  &:before {
    left: -15vw;
    top: -30%;
    background: #fff;
    background: -moz-linear-gradient(left, #e2e7ef 0%, #ffffff 100%);
    background: -webkit-linear-gradient(left, #e2e7ef 0%, #ffffff 100%);
    background: linear-gradient(to right, #e2e7ef 0%, #ffffff 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e2e7ef', endColorstr='#ffffff', GradientType=1); // Responsive

    @include media-breakpoint-down(md) {
      left: -30%;
    }

    @include media-breakpoint-down(sm) {
      left: -35%;
    }
  }

  &:after {
    right: -5vw;
    top: -15%;
    background: #ffffff;
    background: -moz-linear-gradient(left, #ffffff 0%, #e2e7ef 100%);
    background: -webkit-linear-gradient(left, #ffffff 0%, #e2e7ef 100%);
    background: linear-gradient(to right, #ffffff 0%, #e2e7ef 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#e2e7ef', GradientType=1); // Responsive
    @include media-breakpoint-down(md) {
      right: -17%;
    }
  }
}

.shard {
  will-change: transform;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  top: 40px;
  z-index: 1;
  width: 280px;
  animation: float 7s ease-in-out infinite;
  z-index: 2;
}

// Floating animation
@keyframes float {
  0% {
    transform: translate(-50%, 0px);
  }
  50% {
    transform: translate(-50%, -20px);
  }
  100% {
    transform: translate(-50%, 0px);
  }
}

// Page content adjustments.
.page-content {
  position: relative;
  background: #fafafa;
  padding-top: 5.3125rem;

  &:before {
    content: '';
    height: 500px;
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    background: -moz-linear-gradient(top, #ffffff 0%, #fafafa 100%);
    background: -webkit-linear-gradient(top, #ffffff 0%, #fafafa 100%);
    background: linear-gradient(to bottom, #ffffff 0%, #fafafa 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#fafafa', GradientType=0);
  }

  // Color swatches wrapper.
  .color-wrapper {
    float: left;
    @include media-breakpoint-down(xs) {
      float: none;
      max-width: 230px;
      margin: 0 auto;
    }
  }

  .color {
    padding: 25px 20px;
    text-align: center;
    background: $white;
    margin-bottom: 30px;
    box-shadow: 0 0 25px rgba(40, 47, 60, .05), 0 20px 25px rgba(40, 47, 60, .05), 0 3px 4px rgba(40, 47, 60, .05);

    &:last-child {
      margin-right: 0;
    }

    .swatch {
      position: relative;
      border-radius: 50%;
      margin: 0 auto 15px auto;
      width: 110px;
      height: 110px;
    }

    .title {
      display: inline-block;
      font-family: $headings-font-family;
      width: 100%;
      font-size: 1rem;
    }

    .hex-value {
      font-family: $font-family-monospace;
      color: #8f99ac;
      font-size: 12px;
      text-transform: uppercase;
    }
  }
  .content {
    .example {
      margin: 45px 0 60px 0;

      &.emphasized {
        padding: 25px 20px;
        background: $white;
        box-shadow: 0 0 25px rgba(40, 47, 60, .05), 0 20px 25px rgba(40, 47, 60, .05), 0 3px 4px rgba(40, 47, 60, .05);
      }
    }

    .table {
      background: $white;
      box-shadow: 0 0 25px rgba(40, 47, 60, .05), 0 20px 25px rgba(40, 47, 60, .05), 0 3px 4px rgba(40, 47, 60, .05);

      &-striped tbody tr:nth-of-type(odd) {
        background-color: #f7f8fb;
      }

      td,
      th {
        padding: 30px 25px;
      }

      th {
        padding: 15px 25px;
        font-size: 11px;
        font-weight: 300;
        text-transform: uppercase;
        line-height: 1;
        color: #9ba4ae;
        border: none;
      }

      tbody {
        td {
          font-size: 0.8125rem;
          vertical-align: middle;
          h1,
          h2,
          h3,
          h4,
          h5,
          h6,
          p {
            margin: 0;
            line-height: 1;
          }

          p {
            font-size: 1rem !important;
          }
        }
      }
    }
  }
}

// Utilities
@include media-breakpoint-down(sm) {
  .sm-hidden {
    display: none;
  }
}

.ll-image {
  text-indent: -9000px;
}

.hidden {
  opacity: 0;
}

.slide-in {
  will-change: transform;
  opacity: 0;
  transform: translateY(50%);
  transition: transform 850ms cubic-bezier(0.785, 0.135, 0.150, 0.860), opacity 850ms cubic-bezier(0.785, 0.135, 0.150, 0.860);

  &.visible {
    transform: translateY(0);
    opacity: 1;
  }
}

//
// Examples
//

// Icons
.icons-example {
  .icons-example-wrapper {
    padding: 100px 0;
    @media (max-width: 850px) {
      min-width: 100%;
    }

    &.material-icons {
      background: #1C1E21;
    }

    &.font-awesome {
      background: #212529;
    }
  }
}

// Buttons
.example-buttons {
  @media (max-width: 1199px) {
    .buttons-wrapper {
      button {
        margin: 0 5px;
      }
    }
  }

  @media (max-width: 850px) {
    .buttons-wrapper {
      flex-flow: column !important;
      flex-flow: wrap;

      button {
        min-width: 30%;
        margin-bottom: 20px;
        flex: 1;
      }
    }
  }

  @media (max-width: 450px) {
    .buttons-wrapper button {
      min-width: 50%;
    }
  }
}

// Cards
@include media-breakpoint-down(md) {
  #cards .last {
    display: block !important;
  }
}

@include media-breakpoint-down(sm) {
  #cards .card {
    max-width: 350px;
    display: table;
    margin-left: auto;
    margin-right: auto;
  }
}

// Progress bars
@include media-breakpoint-down(sm) {
  #progress-bars {
    .pb-widths {
      margin-bottom: $spacer * 1.25;
    }
  }
}


// Popups/Popovers
#popups-popovers {
  @include media-breakpoint-down(sm) {
    .row>div {
      margin-bottom: $spacer * 1.25;
    }
  }

  @media (max-width: 420px) {
    button {
      display: block;
      width: 100%;
      margin-bottom: 10px;
    }
  }
}

// Badges
#badges {
  @include media-breakpoint-down(sm) {
    .badge {
      display: table;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 10px;
      width: 100%;
    }
  }
}

// Forms
#forms {
  @include media-breakpoint-down(sm) {
    .custom-dropdown-example {
      margin-bottom: $spacer;
    }
  }
}

// Custom controls
@include media-breakpoint-down(sm) {
  .custom-controls-example {
    width: 100%;
    max-width: 100%;
    flex: none;
    display: block;
    padding: 0 !important;
    margin-bottom: $spacer;

    &:last-child {
      margin-bottom: 0;
    }
  }
}


#documentation {
  border-top: 1px solid #ddd;
}

// Footer
.footer-cta {
  padding: 100px 0;
}

.main-footer a:hover { text-decoration: none; }
